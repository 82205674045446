import React, { FunctionComponent, createContext, useContext, useReducer } from "react"
import { MediaMeta } from "../use-player"

export interface State {
  isVisible: boolean
  meta: MediaMeta
  liveRefresh: number | null
}

export interface StateWithReducer {
  state: State
  dispatch: React.Dispatch<Action>
}

const initialState: State = {
  isVisible: false,
  meta: {},
  liveRefresh: 0,
}

const context = createContext<StateWithReducer>({
  state: initialState,
  dispatch: () => null,
})

export const usePlayerContext = (): StateWithReducer => {
  return useContext(context)
}

export const Provider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
}

type Action = {
  type: "update"
  data: Partial<State>
}

const reducer = (state: State, action: Action) => {
  let newState = { ...state }

  switch (action.type) {
    case "update":
      const { isVisible, ...meta } = action.data
      Object.assign(newState, meta)
      newState.isVisible = isVisible ?? true
      return newState

    default:
      return newState
  }
}
