import React, { FunctionComponent, createContext, useContext, useReducer } from "react"
import { setCurrentURL, getURLTime } from "../local-storage"
import { MediaAudio } from "../use-player"

export interface State {
  audio?: HTMLAudioElement
  isPlaying: boolean
  isLive: boolean
}

export interface StateWithReducer {
  state: State
  dispatch: React.Dispatch<Action>
}

const initialState: State = {
  isPlaying: false,
  isLive: false,
}

const context = createContext<StateWithReducer>({
  state: initialState,
  dispatch: () => null,
})

export const useAudioContext = (): StateWithReducer => {
  return useContext(context)
}

export const Provider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
}

type Action =
  | { type: "play"; data?: MediaAudio }
  | { type: "pause" }
  | { type: "seek"; to?: number }
  | { type: "volume"; to?: number }

const reducer = (state: State, action: Action) => {
  let newState = { ...state }

  if (newState.audio === undefined) {
    newState.audio = new Audio()
  }

  switch (action.type) {
    case "play":
      if (action.data !== undefined) {
        newState.audio.src = action.data.src
        newState.isLive = action.data.isLive ?? false
        newState.audio.preload = newState.isLive ? "none" : "auto"
        newState.audio.load()

        if (!newState.isLive) {
          const previousTime = getURLTime(newState.audio.src)
          if (previousTime) newState.audio.currentTime = previousTime
          setCurrentURL(newState.audio.src)
        }
      }

      newState.audio.play()
      if (!newState.isPlaying) newState.isPlaying = true
      return newState

    case "pause":
      newState.audio.pause()
      newState.isPlaying = false
      return newState

    case "seek":
      if (newState.isPlaying) newState.audio.pause()
      newState.audio.currentTime = action.to ?? 0
      if (newState.isPlaying) newState.audio.play()
      return newState

    case "volume":
      newState.audio.volume = action.to ?? 1
      return newState

    default:
      return newState
  }
}
