import React, { FunctionComponent, createContext, useContext, useReducer } from "react"

interface State {
  query: string
}

interface StateWithReducer {
  state: State
  dispatch: React.Dispatch<Action>
}

const initialState: State = {
  query: "",
}

// DEBUG
// console.log("search: Creating 'context' with 'initialState':")
// console.log(initialState)

const context = createContext<StateWithReducer>({
  state: initialState,
  dispatch: () => null,
})

export const useSearchContext = (): StateWithReducer => {
  return useContext(context)
}

export const Provider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // DEBUG
  // console.log("search: Rerendering 'Provider' with 'state':")
  // console.log(state)

  return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
}

export interface PlayData {
  src: string
  isLive?: boolean
}

type Action = { type: "update"; query: string }

// DEBUG
// console.log("search: Creating 'reducer'")

const reducer = (state: State, action: Action) => {
  // DEBUG
  // console.log("search: Reducing action '" + action.type + "'")

  let newState = { ...state }

  switch (action.type) {
    case "update":
      newState.query = action.query
      return newState

    default:
      return newState
  }
}
