import React, { FunctionComponent, createContext, useContext, useReducer, useEffect } from "react"

export interface State {
  reduceMainNav: boolean
  hideThemesNav: boolean
  showSide: boolean
}

interface StateWithReducer {
  state: State
  dispatch: React.Dispatch<Action>
}

const initialState: State = {
  reduceMainNav: false,
  hideThemesNav: false,
  showSide: false,
}

const context = createContext<StateWithReducer>({
  state: initialState,
  dispatch: () => null,
})

export const useLayoutContext = (): StateWithReducer => {
  return useContext(context)
}

export const Provider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const previous = {
      scrollY: 0,
      reduceMainNav: false,
      hideThemesNav: false,
    }

    const handleScroll = () => {
      const updates = []

      const reduceMainNav = window.scrollY > 40
      if (previous.reduceMainNav != reduceMainNav) {
        updates.push({ reduceMainNav: reduceMainNav })
      }

      const hideThemesNav = window.scrollY > 500 && window.scrollY > previous.scrollY
      if (previous.hideThemesNav != hideThemesNav) {
        updates.push({ hideThemesNav: hideThemesNav })
      }

      if (updates.length) {
        dispatch({
          type: "update",
          update: updates.reduce((previous, current) => ({ ...previous, ...current }), {}),
        })
      }

      previous.scrollY = window.scrollY
      previous.reduceMainNav = reduceMainNav
      previous.hideThemesNav = hideThemesNav
    }

    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return <context.Provider value={{ state, dispatch }}>{children}</context.Provider>
}

type Action = { type: "update"; update: Partial<State> }

const reducer = (state: State, action: Action) => {
  let newState = { ...state }

  switch (action.type) {
    case "update":
      newState = { ...newState, ...action.update }
      return newState

    default:
      return newState
  }
}
