const get = (key: string): any | null => JSON.parse(localStorage.getItem(key)) || null
const set = (key: string, value: any): void => localStorage.setItem(key, JSON.stringify(value))

interface Entry {
  url: string
  time: number
}

interface EntryHistory {
  [url: string]: number
}

const PLAYER_CURRENT_URL = "player_current_url"
const PLAYER_CURRENT_TIME = "player_current_time"

const getCurrentURL = (): string => get(PLAYER_CURRENT_URL) || ""

export const setCurrentURL = (url: string) => {
  const previousURL = getCurrentURL()

  if (previousURL && previousURL !== url) {
    pushHistory({ url: previousURL, time: getCurrentTime() })
  }

  set(PLAYER_CURRENT_URL, url)
}

export const setCurrentTime = (time: number): void => set(PLAYER_CURRENT_TIME, time)
export const getCurrentTime = (): number => get(PLAYER_CURRENT_TIME) || 0

const PLAYER_HISTORY = "player_history"

export const getHistory = (): EntryHistory => get(PLAYER_HISTORY) || {}

// Push play entry in history if client played at least 10 seconds
const pushHistory = (entry: Entry) => {
  if (entry.time > 10) {
    const history = getHistory()
    history[entry.url] = entry.time
    set(PLAYER_HISTORY, history)
  }
}

export const getURLTime = (url: string): number | null => {
  if (url == getCurrentURL()) {
    return getCurrentTime()
  }

  const history = getHistory()
  if (url in history) {
    return history[url]
  }
  return null
}
