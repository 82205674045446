import React, { FunctionComponent } from "react"

import { Provider as AudioProvider } from "./context/audio"
import { Provider as PlayerProvider } from "./context/player"

export const Provider: FunctionComponent = ({ children }) => (
  <AudioProvider>
    <PlayerProvider>{children}</PlayerProvider>
  </AudioProvider>
)
