exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-donation-annulation-tsx": () => import("./../../../src/pages/donation/annulation.tsx" /* webpackChunkName: "component---src-pages-donation-annulation-tsx" */),
  "component---src-pages-donation-index-tsx": () => import("./../../../src/pages/donation/index.tsx" /* webpackChunkName: "component---src-pages-donation-index-tsx" */),
  "component---src-pages-donation-merci-tsx": () => import("./../../../src/pages/donation/merci.tsx" /* webpackChunkName: "component---src-pages-donation-merci-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-programmes-tsx": () => import("./../../../src/pages/programmes.tsx" /* webpackChunkName: "component---src-pages-programmes-tsx" */),
  "component---src-pages-recherche-tsx": () => import("./../../../src/pages/recherche.tsx" /* webpackChunkName: "component---src-pages-recherche-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-show-tsx": () => import("./../../../src/templates/show.tsx" /* webpackChunkName: "component---src-templates-show-tsx" */)
}

