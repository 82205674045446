import React from "react"
import type { GatsbyBrowser } from "gatsby"
import { Provider as AudioProvider } from "@/components/player/provider"
import { Provider as SearchProvider } from "@/components/search/context"
import { Provider as LayoutProvider } from "@/components/layout/context"

import "@fontsource/roboto/latin-400.css"
import "@fontsource/roboto/latin-700.css"
import "@fontsource/roboto-slab/latin-500.css"
import "@fontsource/roboto-slab/latin-700.css"

import "./src/styles/main.scss"

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => (
  <LayoutProvider>
    <SearchProvider>
      <AudioProvider>{element}</AudioProvider>
    </SearchProvider>
  </LayoutProvider>
)
